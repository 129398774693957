import { template as template_20ccce882efc493aabcfb5791a79997d } from "@ember/template-compiler";
const FKLabel = template_20ccce882efc493aabcfb5791a79997d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
