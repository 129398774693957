import { template as template_787ddd7ac41e4ad2a4dc1fc17c1be736 } from "@ember/template-compiler";
const WelcomeHeader = template_787ddd7ac41e4ad2a4dc1fc17c1be736(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
