import { template as template_211fa899d6f342b5b0194a8314e21ad2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_211fa899d6f342b5b0194a8314e21ad2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
